<script type="ts">
  import {
    activeStory,
  } from "../contexts/interactive-context";
  import { locale } from "../utils/i18n";
  import { storiesDictionary } from "../data/stories";
  import { fade } from "svelte/transition";

  let currentTrack: TextTrack = null;
  let subtitles: HTMLParagraphElement;
  let showSubtitles: boolean = false;

  $: _locale = locale;
  $: _video = activeStory;

  $: if ($_locale != null) setSubtitles($_locale, $_video);
  $: if ($_video != null) showSubtitles = false; // hide between changes

  function setSubtitles(lang, videoKey) {
    if (currentTrack) {
      for (let i = 0; i < currentTrack.cues.length; i++) {
        var cue = currentTrack.cues[i];
        cue.onenter = undefined;
      }
      currentTrack.mode = "hidden";
    }

    if (videoKey === null) {
      showSubtitles = false;
      return;
    }

    const tag = storiesDictionary[videoKey].videoTag as HTMLVideoElement;

    const textTrack = tag.textTracks.getTrackById(videoKey + "-" + lang);

    if (textTrack) {
      textTrack.mode = "showing";
      for (let i = 0; i < textTrack.cues.length; i++) {
        var cue = textTrack.cues[i];
        cue.onenter = cueEnter;
        cue.onexit = cueExit;
      }
    }

    currentTrack = textTrack;
  }

  function cueEnter() {
    subtitles.innerHTML = this.text;
    showSubtitles = true;
  }

  function cueExit() {
    showSubtitles = false;
  }
</script>

<div class="subtitles-container" class:show={showSubtitles} transition:fade>
  <div class="subtitles">
    <p bind:this={subtitles} />
  </div>
</div>

<style lang="scss">
  @import "../styles/utils.scss";

  .subtitles-container {
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    align-content: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s linear;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 20%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 150px $page-gutter calc(#{$page-gutter} + #{$bottom-nav-height}) $page-gutter;

    @media #{$small-screen} {
      padding: 150px $small-page-gutter calc(#{$small-page-gutter} + #{$bottom-nav-height}) $page-gutter;
    }

    &.show {
      opacity: 1;
    }
  }

  .subtitles {
    width: 100%;

    p {
      width: 50%;
      margin: $page-gutter auto;
      @include subtitle-font;
      text-shadow: 0px 2px 4px rgb(0 0 0 / 60%);
    }
    @media #{$small-screen} {
      p {
        width: 100%;
        padding: rem(6) $small-page-gutter;
      }
    }
  }
</style>
