<script lang="ts">
  import { fade } from "svelte/transition";

  export let strength: "weak" | "strong" = "strong";
</script>

<div class={`bg-shadow ${strength}`} transition:fade />

<style lang="scss">
.bg-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  
  &.strong {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 60%, rgba(0, 0, 0, 0) 100%);
  }
  &.weak {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.15) 60%, rgba(0, 0, 0, 0) 100%);
  }
}
</style>