<script lang="ts">
  import {
    trackIntroDone,
  } from "../../analytics";
  import store, {
    camera,
    introDone,
    introStarted,
    minZoom,
    sceneHeight,
    sceneWidth,
    toBecomeActiveStory,
    toBecomeStartingStory,
    zoomManager,
  } from "../../contexts/interactive-context";

  import { Vector2 } from "../../utils/vectors";

  import { noIntro } from "../../contexts/debug-context";
  import { t } from "../../utils/i18n";
  import { fade } from "svelte/transition";
  import { stories } from "../../data/stories";

  let subtitles;
  let subtitlesVisible = false;

  export function showViewBeforeIntro() {
    $camera.goToPosition(
      new Vector2(sceneWidth / 2, sceneHeight / 2),
      0,
      "easeLinear"
    );

    backgroundTransparent = true;
  }

  introStarted.subscribe((value) => {
    if (value) {
      // These 2 seconds give some breathing room to look at the scene,
      // and also catch some weird cases where the camera seems to want to wait after that 0 second goToPosition before the intro.
      setTimeout(startIntroInInteractive, 2000);
    }
  });

  const onIntroDone = () => {
    trackIntroDone();
    introDone.set(true);
  };

  function startIntroInInteractive() {
    const goToStory = $toBecomeStartingStory ? stories.find((s) => s.id === $toBecomeStartingStory) : null;

    const skipIntro = $noIntro || !!goToStory;
    const introZoomDuration = skipIntro ? 3 : 13;

    if (!skipIntro) {
      setTimeout(() => {
        subtitles = $t("intro.subtitles1");
        subtitlesVisible = true;
        setTimeout(() => {
          subtitles = $t("intro.subtitles2");
        }, 6500);

        // We can't play the intro audio at all (even muted) because we haven't had a user gesture yet.
        // So we also can't use the audio end event to hide the subtitles.
        // So we just hide them after a timeout roughly the length of the intro audio.
        setTimeout(() => subtitlesVisible = false, 13000);
      }, 2500);
    }

    if (goToStory) {
      $zoomManager.zoomFromTo(
        $minZoom,
        // At first sight this looks like an invalid viewport (because the center is not in the center of the frustrum),
        // but the camera will keep recalculating the viewport as it moves towards the story center
        // and this way the zoom level at the destination gets calculated correctly.      
        $zoomManager.calculateReliefZoomLevel({...$store, viewport: {left: 0, right: 0, top: 0, bottom: 0, center: goToStory.centerpoint}}),
        introZoomDuration,
        "easeInOutSine",
        onIntroDone
      );

      $camera.goToPosition(
        goToStory.centerpoint,
        introZoomDuration,
        "easeInOutSine",
        () => {
          toBecomeActiveStory.set(goToStory.id);
        }
      );
    } else {
      $zoomManager.zoomFromTo(
        $minZoom,
        $zoomManager.calculateReliefZoomLevel(),
        introZoomDuration,
        "easeInOutSine",
        onIntroDone
      );
  
      $camera.goToPosition(
        new Vector2(sceneWidth / 2 - 5, sceneHeight / 2 + 68),
        introZoomDuration,
        "easeInOutSine"
      );
    }
  }

  $: backgroundTransparent = false;
</script>

<div
  class="container"
  class:transparent={backgroundTransparent}
>
  {#if subtitlesVisible}
    <div class="subtitles-container" transition:fade>
      <div class="subtitles">
        {subtitles}
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  @import "../../styles/utils.scss";
  .container {
    background: black;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 5;
    transition: background-color 1.5s ease-out;
    pointer-events: none;
    @include interface-font;

    .subtitles-container {
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      position: absolute;
      display: flex;
      align-content: center;
      justify-content: center;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 20%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 150px $page-gutter calc(#{$page-gutter} + #{$bottom-nav-height}) $page-gutter;

      @media #{$small-screen} {
        padding: 150px $small-page-gutter calc(#{$small-page-gutter} + #{$bottom-nav-height}) $page-gutter;
      }

      .subtitles {
        @include subtitle-font;
      }
    }

    &.transparent {
      background-color: transparent;
    }
  }
</style>
