<script type="ts">
  import { fly } from "svelte/transition";
  import { t } from "../utils/i18n";
  import { activeStory } from "../contexts/interactive-context";
  import { panelContent } from "../contexts/panel-context";
  import StoryListenedIndicator from "./StoryListenedIndicator.svelte";
</script>

{#key $activeStory}
  {#if $activeStory}
    <header
      transition:fly={{ x: -200, duration: 1000 }}
      class:dark={!!$panelContent}
    >
      <p>{$t("interface.story")}</p>
      <h1>{$activeStory}</h1>
      <StoryListenedIndicator />
      <slot />
    </header>
  {/if}
{/key}

<style lang="scss">
  @import "../styles/utils.scss";
  header {
    position: fixed;
    top: $page-gutter;
    left: $page-gutter;
    display: block;
    z-index: 5;
    @media #{$small-screen} {
      top: rem(64);
      left: $small-page-gutter;
      z-index: 3;
    }
  }
  h1 {
    @include header-font;
    font-size: rem(24);
    line-height: rem(30);
    font-weight: normal;
    text-transform: capitalize;
    color: $foreground-color;
    margin: 0;
    padding: 0;
    transition: color 0.5s linear;

    @media #{$big-screen} {
      .dark & {
        color: $foreground-color;
      }
    }
  }

  p {
    @include interface-font;
    margin: 0;
    padding: 0;
    transition: color 0.5s linear;

    @media #{$big-screen} {
      .dark & {
        color: $foreground-color;
      }
    }

    @media #{$small-screen} {
      display: none;
    }
  }
</style>
