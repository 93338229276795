<script lang="ts">
  import TranslatedContainer from "./TranslatedContainer.svelte";
  import { activeStory, dragIntroDone, dragIntroStarted, finishedStories, introDone, toBecomeStartingStory } from "../../contexts/interactive-context";
  import RadialCenterShadow from "../../components/RadialCenterShadow.svelte";
  import { StoryID } from "../../types/story";
  import { trackIntroDragStart } from "../../analytics";
  import { noIntro } from "../../contexts/debug-context";

  let containerEl: TranslatedContainer;
  let visible = false;
  const timeouts = [];

  introDone.subscribe(isDone => {
    if (isDone && !$toBecomeStartingStory) start();
  })

  finishedStories.subscribe((stories) => {
    // If the interactive started at a story and that story ended (stories.size > 0),
    // we still need to show the user that they can drag.
    if (!$dragIntroDone && stories.size > 0) start();
  });

  function onDragStart(ev: MouseEvent | TouchEvent) {
    if (visible && !$dragIntroDone) {
      timeouts.forEach(clearTimeout);
      visible = false;
      setTimeout(() => dragIntroDone.set(true), 200);
    }
  }

  let previousActiveStory: StoryID | null = null;

  activeStory.subscribe(story => {
    // If the interactive started at a story and you decide to pan away from that story to emptiness or another story,
    // that means you already know how to drag so we never need to show the drag intro.
    // (This works better than tracking dragStart because it counts any click on buttons etc as a drag start)
    if ($introDone && !$dragIntroDone && !!story && previousActiveStory !== null) {
      timeouts.forEach(clearTimeout);
      visible = false;
      dragIntroDone.set(true);
    }
    previousActiveStory = story;
  });

  function start() {
    if ($noIntro) {
      onDragIntroDone();
      return;
    }
    trackIntroDragStart();
    dragIntroStarted.set(true);
    visible = true;
    containerEl.start();
    timeouts.push(setTimeout(startHandAnimation, 1000));
  }

  function startHandAnimation() {
    animateHand = true;
    timeouts.push(setTimeout(endHandAnimation, 3300));
  }

  function endHandAnimation() {
    animateHand = false;
    timeouts.push(setTimeout(onDragIntroDone, 1000));
  }

  function onDragIntroDone() {
    containerEl.stop();
    visible = false;
    timeouts.push(setTimeout(() => dragIntroDone.set(true), 400)); // duration of animation of TranslatedContainer
  }

  $: animateHand = false;
</script>

<svelte:window
  on:mousedown|passive={onDragStart}
  on:touchstart|passive={onDragStart}
/>

{#if visible && $toBecomeStartingStory}
  <RadialCenterShadow strength="weak" />
{/if}

<div class="container" class:visible>
  <TranslatedContainer bind:this={containerEl}>
    <div class="container-flex">
      <div class="hand-container" class:animateHand>
        <picture>
          <source
            srcset="/assets/sprites/intro/hand-open.webp"
            type="image/webp"
          />
          <img
            src="/assets/sprites/intro/hand-open.png"
            alt="hand open"
            class="hand-5"
            role="presentation"
          />
        </picture>
        <picture>
          <source
            srcset="/assets/sprites/intro/hand-closed.webp"
            type="image/webp"
          />
          <img
            src="/assets/sprites/intro/hand-closed.png"
            alt="hand closed"
            class="hand-0"
            role="presentation"
          />
        </picture>
      </div>
    </div>
  </TranslatedContainer>
</div>

<style lang="scss">
  @import "../../styles/utils.scss";
  .container {
    transition: opacity 0.2s ease-in;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    &.visible {
      opacity: 1;
    }
  }

  .container-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hand-container {
    position: relative;
    width: 120px;
    height: 120px;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: contain;

      &.hand-0 {
        visibility: hidden;
      }
    }

    &.animateHand {
      .hand-0 {
        visibility: visible;
        animation: moveLeftRight 3s ease;
        animation-delay: 0.3s;
      }

      .hand-5 {
        visibility: hidden;
      }
    }

    @keyframes moveLeftRight {
      from {
        transform: translateX(0);
      }

      33.33% {
        transform: translateX(-50%);
      }

      66.67% {
        transform: translateX(+50%);
      }

      to {
        transform: translateX(0);
      }
    }
  }
</style>
