<script lang="ts">
  import type { Store } from "../../contexts/interactive-context";
  import { introDone } from "../../contexts/interactive-context";

  import interactiveStore, {
    zoom,
    zoomManager,
  } from "../../contexts/interactive-context";

  import MathC from "../../utils/math";
  import renderable from "../../entities/renderable";
  import { noZoom } from "../../contexts/debug-context";
  import * as EasingFunctions from "../../utils/easing";
  import EasingHelper from "../../utils/easing-helper";

  let customZoomEasing: EasingHelper<number> | null = null;

  renderable({
    setup() {
      zoomManager.set(this.component);
    },
    render(store) {
      const { minZoom } = store;

      if ($noZoom) {
        zoom.set(minZoom);
        return;
      }

      let zoomDistance = $introDone ? calculateReliefZoomLevel(store) : minZoom;

      if (customZoomEasing && customZoomEasing.running) {
        zoomDistance = customZoomEasing.value;
      }

      if (zoomDistance === store.zoom) {
        return;
      }

      zoom.set(zoomDistance);
    },
  });

  export function calculateReliefZoomLevel({
    camera,
    zoom,
    zoomRelief,
    viewport: { center },
    minZoom,
    maxZoom,
    width,
    height,
  }: Store = $interactiveStore) {
    let newZoom = maxZoom;

    const reliefInRange = zoomRelief.filter(
      (relief) => MathC.distanceBetweenVectors(center, relief) < relief.radius
    );

    if (reliefInRange.length) {
      const offset = reliefInRange.reduce((value, relief) => {
        const radius = (relief.radius * 2 * maxZoom); // Convert zoom radius to diameter and offset it with base value (max zoom)
        const zoom = (maxZoom * Math.min(width, height)) / radius; // Calculate zoom rating based on viewport size and requested ring size

        const normalized =
          MathC.distanceBetweenVectors(center, relief) / relief.radius;
        const progress = 1 - normalized;

        return MathC.slerp(value, zoom, progress);
      }, maxZoom);

      newZoom = MathC.clamp(offset, minZoom, maxZoom);
    }

    // Prevent setting a new position the camera is going to clamp the position anyway
    if (camera.isGoingToClampPosition(center, newZoom)) {
      return zoom;
    }

    return newZoom;
  }

  export function zoomFromTo(
    start: number,
    end: number,
    duration?: number,
    easing?: keyof typeof EasingFunctions,
    completeCallback?: () => void
  ) {
    customZoomEasing = new EasingHelper(
      start,
      end,
      duration ?? Math.abs(end - start) * 3,
      easing ?? "easeOutSine",
      undefined,
      completeCallback
    ).run();
  }
</script>

<slot />
