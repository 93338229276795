<script type="ts">
  import { t } from "../utils/i18n";
  import { trackOpenThemeInfo } from "../analytics";

  import { clickedTheme, panelContent } from "../contexts/panel-context";
  import { audioDatabase } from "../data/asset-database";
  import type { StoryID, Theme } from "../types/story";

  export let theme: Theme;
  export let time: number;
  export let showLabel: boolean;
  export let storyId: StoryID;
  export let totalTime: number;

  const CONTENT_FOLDER = "/assets/panels/";

  function showInfo() {
    audioDatabase.clickAudio.play();
    panelContent.set(`${CONTENT_FOLDER}${theme.content}`);
    clickedTheme.set(theme);
    trackOpenThemeInfo(storyId, theme.title);
  }

  $: isActive = () => time >= theme.start && time < theme.end && showLabel;

  $: themeSize = () => {
    return `${((theme.end - theme.start) / totalTime) * 100}%`;
  };

  $: progress = () => {
    const now = Math.max(time - theme.start, 0);
    const duration = theme.end - theme.start;
    return Math.min((now / duration) * 100, 100);
  };
</script>

<li style="--theme-size: {themeSize()}">
  <button on:click={showInfo} class="theme" class:active={isActive()}>
    <span class="progress" style="--progress: {progress()}%" />
    <span class="shimmer">{@html $t(theme.title)}</span>
  </button>
</li>

<style lang="scss">
  @import "../styles/utils.scss";

  li {
    padding: 0;
    margin: rem(2);
    position: relative;
    flex: 0 1 var(--theme-size);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: rem(4);
      height: 100%;
      background-color: $foreground-color;
      border-radius: rem(5);
      opacity: 0.5;
    }
  }

  @media #{$small-screen} {
    li {
      &:before {
        content: "";
        top: auto;
        right: 0;
        height: rem(5);
        width: 100%;
        opacity: 0.5;
      }
    }
  }

  .progress {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: rem(4);
    height: var(--progress);
    background-color: $foreground-color;
    border-radius: rem(5);
    transition: height 0.2s linear;
    @media #{$small-screen} {
      top: auto;
      bottom: 0;
      width: var(--progress);
      height: rem(5);
      transition: width 0.2s linear;
    }
  }

  button {
    @include interface-button;
    line-height: rem(14);
    white-space: nowrap;
    padding: 0 rem(12);
    text-align: left;
    margin: 0;
    cursor: url("/assets/cursors/hand-pointer.webp"), pointer;
    height: 100%;
    width: 100%;

    .shimmer {
      opacity: 0;
      text-shadow: 1px 1px 2px black;
      transition: all 0.3s ease-in-out;
      transform: translateX(-10px);
      padding-right: 25px;
      background: transparent url("/assets/icons/info.svg") no-repeat;
      background-position: right center;

      @media #{$small-screen} {
        display: block;
        width: 0px;
        transform: translateY(10px);
      }
    }

    &.active .shimmer {
      transform: translateX(0);
      opacity: 1;

      @media #{$small-screen} {
        transform: translateY(0);
        width: auto;
      }
    }

    @media #{$big-screen} {
      &:hover .shimmer {
        transform: translateX(0);
        text-decoration: underline;
        opacity: 1;
      }
    }

    &.active {
      opacity: 1;
    }

    @media #{$small-screen} {
      height: rem(50);
      padding: rem(12) 0;
    }
  }
</style>
