<script lang="ts">
  import { onMount } from "svelte";
  import { locale } from "../utils/i18n";
  import { sendWtfffIFrameBridgeParentMessage } from "../utils/iframe-bridge";
  import type { WtfffIFrameBridgeChildMessage } from "../utils/iframe-bridge";
  import { pausePlayback, resumePlayback } from "../utils/playback";
  import { audioDatabase } from "../data/asset-database";
  import { stories } from "../data/stories";
  import { introStarted } from "../contexts/interactive-context";

  window.addEventListener("message", (e) => handleMessage(e.data as WtfffIFrameBridgeChildMessage));

  let muted = true;
  let audioStarted = false;
  let hasInteracted = false;

  onMount(() => {
    // The interactive starts off muted, so we have to explicitly set all stories/audio to muted in the beginning
    setContentMuted(muted);
  });

  function setContentMuted(muted: boolean) {
    if (!audioStarted && !muted) {
      audioDatabase.backgroundAudio.play();
      audioStarted = true;
    }
    Object.values(audioDatabase).forEach((a) => a.mute(muted));
    stories.forEach((s) => (s.videoTag.muted = muted));
  }

  const handleMessage = (message: WtfffIFrameBridgeChildMessage) => {
    switch (message.type) {
      case "setLocale":
        return locale.set(message.payload.locale);
      case "setMuted":
        muted = message.payload.muted;
        return setContentMuted(muted);
      case 'startPlayback':
        return introStarted.set(true);
      case "pausePlayback":
        return pausePlayback();
      case "resumePlayback":
        return resumePlayback();

      default:
        console.warn("Unknown message: " + JSON.stringify(message));
    }
  };

  const interactEventTypes = ["focus", "click", "touchstart", "keydown"];
  const onInteract = () => {
    if (hasInteracted) {
      return;
    }
    sendWtfffIFrameBridgeParentMessage("interacted");
    // necessary to make video auto-playing work on Safari / Firefox
    for (const story of stories) {
      story.videoTag.play().catch(() => {});
      story.videoTag.pause();
    }
    hasInteracted = true;
    interactEventTypes.forEach((type) => document.removeEventListener(type, onInteract));
  };
  interactEventTypes.forEach((type) => window.addEventListener(type, onInteract));
</script>

<base target="_parent" />
