import { writable } from "svelte/store";
import storeCombine from "../utils/store-combine";
import { stories } from "../data/stories";
import { Vector2 } from "../utils/vectors";
import { getStartingStoryID } from "../utils/search-params";
export const introStarted = writable(false);
export const introDone = writable(false);
export const dragIntroStarted = writable(false);
export const dragIntroDone = writable(false);
export const width = writable(0);
export const height = writable(0);
export const pixelRatio = writable(window.devicePixelRatio);
export const smallScreen = writable(true);
export const sceneWidth = 3897.5;
export const sceneHeight = 3265;
export const canvas = writable();
export const context = writable();
export const camera = writable();
export const time = writable(0);
export const zoomManager = writable();
export const minZoom = writable(0.25);
export const maxZoom = writable(1.8);
export const zoom = writable(1.8);
export const storyActive = writable(false);
export const viewport = writable({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    center: new Vector2(0, 0),
});
export const isCameraMovingByUser = writable(false);
export const cursor = writable("drag");
export const click = writable();
export const zoomRelief = writable(stories.map((s) => ({
    x: s.centerpoint.x,
    y: s.centerpoint.y,
    radius: s.zoomRadius,
})));
export const storyEnded = writable(false);
export const activeStory = writable(null);
export const clickedStory = writable(null);
export const toBecomeActiveStory = writable(null);
export const toBecomeStartingStory = writable(getStartingStoryID());
export const storyDistance = writable(null);
export const closestStory = writable(null);
export const finishedStories = writable(new Set());
const store = storeCombine({
    camera,
    width,
    height,
    pixelRatio,
    canvas,
    context,
    time,
    viewport,
    zoom,
    minZoom,
    maxZoom,
    storyActive,
    activeStory,
    toBecomeActiveStory,
    closestStory,
    storyDistance,
    zoomRelief,
});
export default store;
