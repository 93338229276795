<script lang="ts">
  import { t } from "../../utils/i18n";

  import { storyHotspots } from "../../contexts/debug-context";
  import {
    activeStory,
    camera,
    closestStory,
    introDone,
    isCameraMovingByUser,
    toBecomeActiveStory,
    storyDistance,
    viewport,
    clickedStory,
    toBecomeStartingStory,
    dragIntroDone,
  } from "../../contexts/interactive-context";
  import renderable from "../../entities/renderable";
  import MathC from "../../utils/math";
  import { storiesDictionary } from "../../data/stories";
  import { audioDatabase } from "../../data/asset-database";
  import { fade } from "svelte/transition";
  import { Vector2 } from "../../utils/vectors";
  import type { Viewport } from "../../types/viewport";
  import * as EasingFunctions from "../../utils/easing";
  import { playVideo } from "../../utils/video";
  import { StoryID } from "../../types/story";
  import { trackVideoStart } from "../../analytics";
  import rg4js from 'raygun4js';

  let timeout;
  let active: StoryID | null = null;

  let playButtonActive = false;
  let activatedVideos = new Set();

  isCameraMovingByUser.subscribe(onMovingChanged);

  function onMovingChanged(moving: boolean) {
    if (!moving && $viewport?.center) {
      if ($toBecomeActiveStory && $toBecomeActiveStory != active) {
        const story = storiesDictionary[$toBecomeActiveStory];
        const duration = 1 + ($storyDistance / story.activationRadius) * 3;
        $camera.goToPosition(
          story.centerpoint,
          duration,
          "easeInOutCubic",
          () => {
            setVideo(story.id);
          }
        );
      } else if (!toBecomeActiveStory) {
        setVideo(null);
      }
    }
  }

  clickedStory.subscribe(onStoryClicked);

  function onStoryClicked(name: StoryID | null) {
    if (name) {
      const story = storiesDictionary[name];
      const duration = 0.2 + ($storyDistance / story.activationRadius) * 0.4;
      $camera.goToPosition(story.centerpoint, duration, "easeInOutSine", () => {
        setVideo(name);
        clickedStory.set(null);
      });
    }
  }
  
  activeStory.subscribe(onActiveStoryChanged);

  function onActiveStoryChanged(name: StoryID | null) {
    if ($toBecomeStartingStory && name) {
      // toBecomeStartingStory isn't relevant anymore when the first story has started
      toBecomeStartingStory.set(null);
    }

    if (!name) {
      playButtonActive = false;
      stopAllOtherVideoPlayback(name);
      
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }

      return;
    }

    if (timeout) {
      return;
    }

    audioDatabase.preVideoAudio.play();

    activatedVideos.add(name);
    timeout = setTimeout(() => startVideoPlayback(name), 1000);
  }

  toBecomeActiveStory.subscribe(onToBecomeActiveStoryChanged);

  function onToBecomeActiveStoryChanged(name: StoryID | null) {
    if (name) {
      audioDatabase.activationAudio.play();
    }
    if ($toBecomeStartingStory && !name && $dragIntroDone) {
      // If the user moves away from the starting story before it starts, toBecomeStartingStory becomes irrelevant
      toBecomeStartingStory.set(null);
    }
  }

  function startVideoPlayback(name: StoryID) {
    timeout = undefined;
    playVideo(name).catch(showPlayButton);
    stopAllOtherVideoPlayback(name);
  }

  function showPlayButton(e: Error) {
    if (e.name == "NotAllowedError") {
      playButtonActive = true;
    } else {
      rg4js("send", e);
      console.warn(e);
    }
  }

  function stopAllOtherVideoPlayback(name: StoryID) {
    Object.entries(storiesDictionary)
      .filter(([key, _]) => key !== name)
      .forEach(([_, { videoTag }]) => videoTag.pause());
  }

  function findClosestStory(center: Vector2) {
    return Object.values(storiesDictionary)
      .map((story) => {
        return {
          story,
          distance: MathC.distanceBetweenVectors(story.centerpoint, center),
        };
      })
      .sort((a, b) => a.distance - b.distance)[0]
  }

  function setVideo(name: StoryID) {
    if (active === name) {
      return;
    }

    active = name;
    activeStory.set(name);
  }

  function playCurrentVideo() {
    playVideo(active).catch(console.warn);
    trackVideoStart(active);
    playButtonActive = false;
  }

  function drawDebugCircles(
    context: CanvasRenderingContext2D,
    viewport: Viewport
  ) {
    if (!$storyHotspots) {
      return;
    }

    for (const story of Object.values(storiesDictionary)) {
      const {
        centerpoint,
        activationRadius: activationRadius,
        zoomRadius,
      } = story;

      const stop = new Path2D();
      const zoom = new Path2D();
      const point = new Path2D();

      stop.arc(centerpoint.x, centerpoint.y, activationRadius, 0, 2 * Math.PI);
      zoom.arc(centerpoint.x, centerpoint.y, zoomRadius, 0, 2 * Math.PI);
      point.arc(centerpoint.x, centerpoint.y, 10, 0, 2 * Math.PI);

      context.lineWidth = 10;
      context.fillStyle = "#FFFFFF90";
      context.strokeStyle = "#FFFFFF90";
      context.fill(point);

      context.strokeStyle = "#FFFFFF40";
      context.stroke(stop);

      context.strokeStyle = "#FF000040";
      context.stroke(zoom);
    }

    const center = new Path2D();
    center.arc(viewport.center.x, viewport.center.y, 10, 0, 2 * Math.PI);
    context.fillStyle = "#FF0000";
    context.fill(center);
  }

  renderable(({ context, viewport }) => {
  if (!$introDone) {
      return;
    }

    const closest = findClosestStory(viewport.center);
    
    closestStory.set(closest.story.id);
    storyDistance.set(closest.distance);

    if (closest.distance < closest.story.activationRadius) {
      toBecomeActiveStory.set(closest.story.id);
    } else {
      setVideo(null);
      toBecomeActiveStory.set(null);
    }

    if (active && active == closest.story.id) {
      closest.story.videoTag.volume = MathC.clamp(1 - EasingFunctions.easeInQuint(closest.distance, 0, 1, closest.story.activationRadius), 0, 1);
    }

    drawDebugCircles(context, viewport);
  });
</script>

{#if playButtonActive}
  <div
    class="play-button"
    in:fade={{ delay: 1500, duration: 500 }}
    out:fade={{ duration: 500 }}
  >
    <button type="button" on:click={playCurrentVideo}>{$t("video.play")}</button>
  </div>
{/if}

<svelte:window />

<style lang="scss">
  @import "../../styles/utils.scss";

  .play-button {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    button {
      @include interface-button;
      font-size: rem(16);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $foreground-color;
      height: rem(142);
      width: rem(142);
      border-radius: rem(142);
      cursor: pointer;
      box-shadow: 0 0 120px 30px black, inset 0 0 60px 0 black;
    }
  }
</style>
