<script lang="ts">
  import { pausePlayback, resumePlayback } from "../utils/playback";
  import { isTouchDevice } from "../utils/touch";
  
  if (isTouchDevice() ) {
    window.addEventListener("focus", function () {
      resumePlayback();
    });

    window.addEventListener("blur", function () {
      pausePlayback();
    });
  }
</script>
