<svelte:options accessors={true} />

<script lang="ts">
  import { activeStory, introDone, toBecomeActiveStory } from "../contexts/interactive-context";
  import { sendWtfffIFrameBridgeParentMessage } from "../utils/iframe-bridge";

  activeStory.subscribe(onActiveStoryChanged);

  function onActiveStoryChanged(storyId: string | null) {
    if ($introDone) {
      sendWtfffIFrameBridgeParentMessage("selectStory", { storyId });
    }
  }
</script>
