<script lang="ts">
  import "./utils/debug-helper";
  import "./utils/scene-size-calculator";
  import { introDone } from "./contexts/interactive-context";
  import { loadAssets } from "./data/asset-database";

  import Overview from "./components/debugging/Overview.svelte";

  import Canvas from "./components/core/Canvas.svelte";
  import OffscreenVideoRenderer from "./components/core/OffscreenVideoRenderer.svelte";
  import Background from "./components/Background.svelte";
  import FrameRate from "./components/FrameRate.svelte";
  import Camera from "./components/core/Camera.svelte";
  import StoryHeader from "./components/StoryHeader.svelte";
  import Interface from "./components/Interface.svelte";
  import Themes from "./components/Themes.svelte";
  import Panel from "./components/Panel.svelte";
  import ZoomManager from "./components/core/ZoomManager.svelte";
  import ElementMover from "./components/debugging/ElementPlacer.svelte";

  import Myriam from "./views/myriam/Scene.svelte";
  import Roos from "./views/roos/Scene.svelte";
  import Yara from "./views/yara/Scene.svelte";
  import Noah from "./views/noah/Scene.svelte";
  import Manon from "./views/manon/Scene.svelte";

  import VideoSelector from "./components/core/VideoSelector.svelte";
  import Subtitles from "./components/Subtitles.svelte";
  import Wtfffs from "./views/wtfffs.svelte";
  import Sky from "./views/Sky.svelte";
  import Noise from "./views/Noise.svelte";
  import Loader from "./components/Loader.svelte";
  import UnsupportedBrowser from "./components/UnsupportedBrowser.svelte";
  import Intro from "./views/intro/Intro.svelte";
  import { movableElements, noIntro } from "./contexts/debug-context";
  import Shadow from "./views/Shadow.svelte";
  import BackgroundAudio from "./components/BackgroundAudio.svelte";
  import VideoGradients from "./components/VideoGradients.svelte";
  import VideoTitles from "./components/VideoTitles.svelte";
  import WindowBlurListener from "./components/WindowBlurListener.svelte";
  import VideoLoader from "./components/VideoLoader.svelte";
  import { onMount } from "svelte";
  import { trackAppStart } from "./analytics";
  import { isUnSupportedBrowser } from "./utils/device";
  import { locale } from "./utils/i18n";
  import LoadingError from "./components/LoadingError.svelte";
  import IFrameBridgeListener from "./components/IFrameBridgeListener.svelte";
  import UrlController from "./components/UrlController.svelte";
  import DragIntro from "./views/intro/DragIntro.svelte";
  import { sendWtfffIFrameBridgeParentMessage } from "./utils/iframe-bridge";

  let intro: Intro | undefined;

  onMount(() => {
    trackAppStart();
  });

  async function loadAndCheckDone() {
    try {
      await loadAssets();
    } catch (e) {
      onLoadingAnimationDone();
      throw e;
    }
  }

  const onLoadingAnimationDone = () => {
    intro?.showViewBeforeIntro();
    sendWtfffIFrameBridgeParentMessage("loaded")
    loadingAnimationDone = true;
  };

  $: noIntro;
  $: introDone;
  $: loadingAnimationDone = false;
</script>

<main lang={$locale}>
  {#if isUnSupportedBrowser()}
    <UnsupportedBrowser />
  {:else}
    {#if !loadingAnimationDone}
      <Loader onDone={onLoadingAnimationDone} />
    {/if}

    <OffscreenVideoRenderer />
    <WindowBlurListener />
    <IFrameBridgeListener />

    {#await loadAndCheckDone() then number}
      {#if !$introDone}
        <Intro bind:this={intro} />
      {/if}
      <DragIntro />

      <StoryHeader />

      <Canvas>
        <Shadow />
        <Background />

        <Overview />

        {#if $movableElements}
          <ElementMover />
        {/if}

        <Sky />

        <Myriam />
        <Noah />
        <Yara />
        <Roos />
        <Manon />

        <ZoomManager />

        <Camera />
        <Subtitles />

        <Wtfffs />

        {#if !$movableElements}
          <Noise />
          <VideoGradients />
        {/if}

        <VideoLoader />
      </Canvas>

      <BackgroundAudio />
      <UrlController />

      <Interface>
        <FrameRate />

        <Themes />

        <VideoTitles />
        {#if !$movableElements}
          <VideoSelector />
        {/if}

        <Panel />
      </Interface>
    {:catch error}
      <LoadingError {error}/>
    {/await}
  {/if}
</main>

<style lang="scss">
  @import "./styles/fonts.scss";
  @import "./styles/utils.scss";

  :global(*) {
    box-sizing: border-box;
  }

  :global(body) {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: "GarnettRegular", Arial, Helvetica, sans-serif;
  }

  main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background: black;
  }
</style>
