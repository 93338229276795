<script lang="ts">
  import type { Story } from "../types/story";
  import {
    activeStory,
    clickedStory,
    toBecomeActiveStory,
    toBecomeStartingStory,
    introDone,
  } from "../contexts/interactive-context";
  import { t } from "../utils/i18n";
  import { trackVideoTitle } from "../analytics";
  import { fade } from "svelte/transition";
  import RadialCenterShadow from "./RadialCenterShadow.svelte";

  export let story: Story;

  function onClick() {
    clickedStory.set(story.id);
  }

  toBecomeActiveStory.subscribe((storyId) => {
    if (storyId == story.id) {
      trackVideoTitle(storyId);
    }
  });

  // In the case the interactive starts off with a story (deeplinked), we want to show some extra context: a supertitle and a play button.
  $: showExtraUI = $toBecomeActiveStory == story.id && $toBecomeStartingStory && !$activeStory
</script>

{#if $toBecomeActiveStory == story.id && !$activeStory}
  <RadialCenterShadow />
{/if}

<div
  class="story-title"
  class:visible={$toBecomeActiveStory == story.id && !$activeStory}
  aria-hidden={!$introDone}
  on:click|stopPropagation={onClick}
>
  {#if showExtraUI}
    <p class="supertitle" transition:fade>{$t('intro.startingStorySuperTitle')}</p>
  {/if}
  <h2>
    <span class="sr-only">{story.title}</span>
    {#each story.title as char, i}
      <span class="letter" style="transition-delay: {i * 50}ms;" aria-hidden="true">
        {char}
      </span>
    {/each}
  </h2>
  {#if showExtraUI}
     <button class="play-button" aria-label={$t('video.play')}s on:click|stopPropagation={onClick} transition:fade>
      <img src="/assets/icons/play.svg" alt={$t('video.play')} />
    </button>
  {/if}
</div>

<style lang="scss">
  @import "../styles/utils.scss";

  .story-title {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    transition: opacity 0.25s;
    Z-index: 1;

    .supertitle {
      font-size: max(1.5vw, #{rem(12)});
      color: $foreground-color;
      margin: 0;
      padding: 0;
      text-align: center;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
    }

    .letter {
      transition: opacity 0.25s, transform 0.25s;
      opacity: 0;
      display: inline-block;
      transform: translateY(60%);
    }

    &.visible {
      opacity: 1;
      pointer-events: initial;
      cursor: url("/assets/cursors/hand-pointer.webp"), pointer;

      .letter {
        opacity: 1;
        transform: translateY(0);
      }
    }

    h2 {
      @include header-font;
      white-space: nowrap;
      pointer-events: none;
      display: block;
      font-size: max(7vw, #{rem(72)});
      line-height: max(8.5vw, #{rem(92)});
      color: $foreground-color;
      margin: 0;
      padding: 0;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
      text-align: center;
    }

    .play-button {
      --play-button-size: max(5vw, #{rem(48)});

      @include interface-button;
      font-size: calc(var(--play-button-size) * 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: $foreground-color;
      height: var(--play-button-size);
      width: var(--play-button-size);
      border-radius: var(--play-button-size);
      cursor: url("/assets/cursors/hand-pointer.webp"), pointer;
      margin: calc(var(--play-button-size) * 0.3) auto 0;
      transition: background-color 0.25s;

      &:active {
        background-color: lighten($foreground-color, 10%);
      }

      img {
        width: 70%;
        height: 70%;
      }
    }

    .sr-only {
      position: absolute;
      z-index: -1;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
</style>
