<script type="ts">
  import { t } from "../utils/i18n";
  import { clickedTheme, panelContent } from "../contexts/panel-context";
  import { activeStory, camera } from "../contexts/interactive-context";
  import { storiesDictionary } from "../data/stories";
  import { locale } from "../utils/i18n";
  import { audioDatabase } from "../data/asset-database";
  import LoaderText from "./LoaderText.svelte";
  import { playVideo } from "../utils/video";
  import { trackPlayTheme } from "../analytics";

  panelContent.subscribe(toggleContent);
  locale.subscribe(onLanguageChanged);

  let transforming = false;
  let htmlContent;
  let panel;

  function onLanguageChanged(lang: {} | null) {
    if (!lang) {
      return;
    }
    toggleContent($panelContent);
  }

  function handleClickOutside(e) {
    if (!panel.contains(e.target)) {
      close();
    }
  }

  function toggleContent(content) {
    if (content) {
      $camera.stop();
      transforming = true;
      htmlContent = undefined;
      let info = `${content}_${$locale}.html`;

      if (!!$activeStory) {
        const tag = storiesDictionary[$activeStory]
          .videoTag as HTMLVideoElement;
        tag.pause();
      }

      document.addEventListener("click", handleClickOutside, true);

      fetch(info)
        .then((response) => response.text())
        .then((html) => (htmlContent = html));
    }
  }

  function playTheme() {
    const theme = $clickedTheme;
    trackPlayTheme($activeStory, theme.title);
    closePanel();
    setTimeout(
      () => playVideo($activeStory, theme.start).catch(console.warn),
      500
    );
  }

  function closePanel() {
    setTimeout(() => (transforming = false), 1000);
    audioDatabase.clickAudio.play();
    panelContent.set(undefined);
    clickedTheme.set(undefined);
    document.removeEventListener("click", handleClickOutside, true);
    $camera.resume();
  }

  function close() {
    closePanel();
    playVideo($activeStory).catch(console.warn);
  }

  function help() {
    toggleContent("/assets/panels/theme_help");
  }
</script>

<div class="panelbackground {$panelContent ? 'show' : ''}" />
<div
  class="panel {$panelContent ? 'show' : ''} {transforming
    ? 'transforming'
    : ''}"
  bind:this={panel}
>
  <div class="header">
    <div class="header-buttons">
      <!-- TODO: correct urls + translations -->
      <a href={$t('panel.helpUrl')} target="_parent">{$t('panel.help')}</a>
      <a href={$t('panel.storyUrl')} target="_parent">{$t('panel.story')}</a>
    </div>
    <!--{#if $clickedTheme}-->
    <!--  <button on:click={playTheme} class="theme"-->
    <!--    >{$t($clickedTheme.title)}</button-->
    <!--  >-->
    <!--{/if}-->

    <button on:click={close} class="close" aria-label={$t("interface.close")}>
      {$t("interface.close")}
    </button>
  </div>
  <div class="content">
    {#if !htmlContent}
      <div style="color: black !important;">
        <LoaderText text={$t("intro.loading")} color="black" />
      </div>
    {:else}
      <div class="html-content">
        {@html htmlContent}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  @import "../styles/utils.scss";
  .panelbackground {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    width: 100%;
    z-index: 3;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;

    &.show {
      opacity: 0.5;
    }
  }

  .panel {
    pointer-events: initial;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
    width: 50%;
    max-width: rem(800);
    min-width: rem(720);
    background-color: $background-color;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 720px) {
      width: 100%;
      min-width: 100%;
    }

    @media #{$small-screen} {
      right: 0;
      width: 100%;
      transform: translateX(0) translateY(100%);
      &.show {
        transform: translateY(0);
      }
    }

    &.transforming {
      transition: transform 0.8s cubic-bezier(0.42, 0, 0, 1);
    }

    &.show {
      transform: translateX(0);
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(28) $page-gutter;

      @media #{$small-screen} {
        padding: $small-page-gutter;
      }
    }

    .header-buttons {
      display: flex;
      gap: rem(10);
      margin-left: rem(110);

      @media #{$small-screen} {
        gap: rem(5);
        margin-left: 0;
      }
    }

    .content {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-y: auto;
    }

    .help-button {
      @include solid-rounded-button($background: black, $foreground: $background-color);
    }

    .share-button {
      @include solid-rounded-button("/assets/icons/arrow-top-right-pink.svg", black, $background-color);

      margin: 10px auto;
    }

    .footer-buttons {
      margin: 30px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .theme {
      @include solid-rounded-button("/assets/icons/replay_panel.svg", black, $background-color);
    }

    .close {
      cursor: pointer;
      background: #000 url("/assets/icons/close.svg") center center no-repeat;
      padding: 0;
      margin: 0;
      width: rem(36);
      height: rem(36);
      text-indent: -9999rem;
      border: 1px solid black;
      border-radius: rem(36);
    }

    .html-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: calc(#{$page-gutter} + #{rem(128)}) $page-gutter calc(#{$page-gutter} + #{$bottom-nav-height}) $page-gutter;
      
      @media #{$small-screen} {
        padding: calc(#{$small-page-gutter} + #{rem(96)}) $small-page-gutter calc(#{$small-page-gutter} + #{$bottom-nav-height}) $small-page-gutter;
      }
    }
  }

  .html-content :global(h1) {
    @include header-font;
    font-size: rem(60);
    line-height: rem(66);
    text-align: center;
    color: $foreground-color;
    max-width: 90%;
    margin: 0 0 rem(20) 0;

    @media #{$small-screen} {
      font-size: rem(24);
      line-height: rem(28);
      max-width: none;
      margin: 0 0 rem(10) 0;
    }
  }

  .html-content :global(p) {
    font-size: rem(20);
    line-height: rem(25);
    color: $foreground-color;
    font-family: "GarnettRegular", Arial, Helvetica, sans-serif;
    text-align: center;
    max-width: 80%;
    align-self: center;
    margin: 0 0 rem(30) 0;
    white-space: pre-wrap;

    @media #{$small-screen} {
      max-width: none;
    }
  }

  a:not(.inline-link),
  .html-content :global(a:not(.inline-link)) {
    @include solid-rounded-button($background: $foreground-color, $foreground: $background-color);
  }

  .html-content :global(.inline-link) {
    color: $foreground-color;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
</style>
