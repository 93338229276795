<script lang="ts">
  import { fade } from "svelte/transition";

  import { t } from "../utils/i18n";

  import { loadingProgress } from "../data/asset-database";
  import LoaderText from "./LoaderText.svelte";

  export let onDone: Function;

  let progress = 0;
  let animating = false;

  loadingProgress.subscribe(onNewProgress);

  function onNewProgress(newProgress: number) {
    progress = newProgress * 100;
    if (!animating) {
      animate();
    }
  }

  function animate() {
    if (interalProgress === Math.ceil(progress)) {
      if (interalProgress === 100) {
        done = true;
        setTimeout(onDone, 200);
      }

      animating = false;
      return;
    }

    animating = true;
    interalProgress = interalProgress + 1;
    setTimeout(animate, 10);
  }

  $: done = false;
  $: interalProgress = 0;
</script>

{#if !done}
  <div class="loader" out:fade>
    <p class="counter">{interalProgress}%</p>
    <div><LoaderText text={$t("intro.loading")} /></div>
  </div>
{/if}

<style lang="scss">
  @import "../styles/utils.scss";

  .loader {
    background: black;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 6;
  }

  .counter {
    @include header-font;
    font-variant-numeric: tabular-nums;
    font-size: 72px;
    letter-spacing: 3px;
    text-transform: capitalize;
    color: $foreground-color;
    margin-bottom: 10px;
  }
</style>
