import { stories } from "../data/stories";
import { languages } from "./i18n";
export function getStartingLanguage() {
    var _a;
    const langFromSearchParams = new URLSearchParams(window.location.search).get("lang");
    return ((_a = languages.find((l) => l.src == langFromSearchParams)) === null || _a === void 0 ? void 0 : _a.src) || "nl";
}
export function getStartingStoryID() {
    var _a, _b;
    const storyFromSearchParams = new URLSearchParams(window.location.search).get("story");
    return (_b = (_a = stories.find((s) => s.id == storyFromSearchParams)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
}
