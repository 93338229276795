import { audioDatabase } from "../data/asset-database";
import { stories } from "../data/stories";
import { activeStory } from "../contexts/interactive-context";
import { panelContent } from "../contexts/panel-context";
import { playVideo } from "./video";
/*
This file is for playing/pausing any audio+video content.
It has a little 200ms 'throttle' to prevent the audio from stuttering when it's paused+resumed quickly.
(This is mostly to account for the double focus+blur events when switching focus between iframe and parent window.)
*/
let _activeStory = null;
activeStory.subscribe((val) => {
    _activeStory = val;
});
let _panelContent = null;
panelContent.subscribe((val) => {
    _panelContent = val;
});
let pauseTimeout = null;
let playTimeout = null;
export function pausePlayback() {
    if (playTimeout) {
        clearTimeout(playTimeout);
        playTimeout = null;
        return;
    }
    pauseTimeout = setTimeout(() => {
        pauseTimeout = null;
        audioDatabase.backgroundAudio.pause();
        stories.forEach((s) => s.videoTag.pause());
    }, 200);
}
export function resumePlayback() {
    if (pauseTimeout) {
        clearTimeout(pauseTimeout);
        pauseTimeout = null;
        return;
    }
    playTimeout = setTimeout(() => {
        playTimeout = null;
        if (!_panelContent) {
            playVideo(_activeStory).catch(console.warn);
        }
        audioDatabase.backgroundAudio.play();
    }, 200);
}
